import React from "react";
import { useUserData } from "../utils/UserData";
import { Link } from "react-router-dom";

export default function ContactPage() {
  const data = useUserData();

  return (
    <>
      <main className="page">
        <h2 className="page_title">Contact Me</h2>

        <section className="page_contact">
          <Link to={data.socialMedia.github} className="page_contact">
            <i className="fa-brands fa-github contact-icons"></i>
            <p>Github</p>
          </Link>

          <Link to={data.socialMedia.linkedin} className="page_contact">
            <i className="fa-brands fa-linkedin-in contact-icons"></i>
            <p>Reach me on Linkedin</p>
          </Link>

          <Link
            to={`mailto:${data.socialMedia.email}`}
            className="page_contact"
          >
            <i className="fa-solid fa-envelope contact-icons"></i>
            <p>{data.socialMedia.email}</p>
          </Link>

          <Link to={data.socialMedia.devTo} className="page_contact">
            <i className="fa-brands fa-dev contact-icons"></i>
            <p>Dev Community</p>
          </Link>
          
        </section>
      </main>
    </>
  );
}
