import React from "react";
import ProjectsList from "../components/ProjectsList";

export default function PageBlog() {
  return (
    <div>
      <main className="page">
        <h2 className="page_title">Projects</h2>
        <ProjectsList />
      </main>
    </div>
  );
}
