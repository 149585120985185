import React from "react";
import SingleProject from "../components/SingleProject";

export default function PageSinglePost() {
  return (
    <div>
      <main className="page">
        <SingleProject />
      </main>
    </div>
  );
}
