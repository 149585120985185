import React from "react";
import { Link } from "react-router-dom";
import { useUserData } from "../utils/UserData";
import BackToTop from "./BackToTop";

export default function Footer() {
  const data = useUserData();

  return (
    <footer className="footer">
      <BackToTop />
      <nav className="footer_icons">
        <li>
          <Link to={`mailto:${data.socialMedia.email}`} rel="noopener noreferrer">
            <i className="fa-solid fa-envelope footer-icons" />
          </Link>
        </li>

        <li>
          <Link to={data.socialMedia.linkedin} rel="noopener noreferrer">
            <i className="fa-brands fa-linkedin-in footer-icons" rel="noopener noreferrer" />
          </Link>
        </li>

        <li>
          <Link to={data.socialMedia.github} rel="noopener noreferrer">
            <i className="fa-brands fa-github footer-icons" />
          </Link>
        </li>

        <li>
          <Link to={data.socialMedia.devTo} rel="noopener noreferrer">
            <i className="fa-brands fa-dev footer-icons" />
          </Link>
        </li>
      </nav>

      <p>© 2022 - {new Date().getFullYear()} - All Rights Reserved.</p>
    </footer>
  );
}
