import React, { createContext, useContext } from "react";

const UserData = createContext();

export const useUserData = () => useContext(UserData);

export const UserInfo = ({ children }) => {
  const userDetails = {
    name: "Sarah Siqueira",
    jobTitle: "Full Stack Developer",
    shortDescription:
      "Open source enthusiast, since 2015 building web applications, Shopify Themes and Apps, WordPress Themes and Plugins, Gutenberg Blocks, including frontend technologies like React, Next & others.",
    longDescription:
      "I am a full-stack developer, with 9+ years of experience.  Experience that made me capable of quickly adapting to new technologies and frameworks, and always looking to the latest trends and technologies in web development. I started as a freelancer back in 2015 working on Shopify and WordPress projects. That led me to learn among other things, PHP, JavaScript, Ruby on Rails, Node, CSS, and SASS/SCSS and also gave me experience in deployment tasks, with CI/CD routines as GitHub workflows and Bitbucket pipelines, working with cloud servers such as Digital Ocean and the famous AWS. Later in 2018, I founded my startup, in which I was responsible for IT and also took part in UX/UI decisions, including marketing strategies (sincerely, not sure how is the harder part yet). In the last couple of years, I decided to move my career abroad and since then, I have had the opportunity to work with awesome developers, product managers, and designers from lots of places around the world, including the US, Pakistan, Mexico, Colombia, South Africa, UK. A fun fact about me is that I am a former accountant and eternal physics student, it may seem random but in the end, everything connects.",
    basedOn: "Brazil",
    socialMedia: {
      linkedin: "https://www.linkedin.com/in/sarahcssiqueira",
      github: "https://github.com/sarahcssiqueira",
      twitter: "https://twitter.com/sarahcssiqueira",
      devTo: "https://dev.to/sarahcssiqueira",
      email: "sarahcosiqueira@gmail.com",
    },
    apiToConsume: {
      devTo: "https://dev.to/api/articles?username=sarahcssiqueira",
      github: "https://api.github.com/users/sarahcssiqueira/repos",
    },
    gitHubPageDesc:
      "Used for millions of developers around the globe, GitHub does not require a presentation. As an open source enthusiast, I use GitHub, among other things, to share some code samples that can be useful for other devs. Below you can see my public GitHub repositories. If some of them are useful for you, please consider leaving a star.)",
    blogPageDesc:
      "I write content about technology, programming, and sometimes, how I see the world. The main goal is writing for my own future reference (and avoid spending hours 'googling' for stuff I forgot), but I also hope something to be useful for other developers.",
  };

  return <UserData.Provider value={userDetails}>{children}</UserData.Provider>;
};
