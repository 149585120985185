import React from "react";

export default function NotFound() {
  return (
    <div>
      <div className="page">
        <h2 className="page_title"> Ops! Nothing found here</h2>
      </div>
    </div>
  );
}
