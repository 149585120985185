import React from "react";
import { Link } from "react-router-dom";

export default function skillsPage() {
  return (
    <>
      <main className="page">
        <h2 className="page_title">Skills</h2>

        <p>
          Through the years, I have worked with or interacted with lots of tools
          and technologies. I listed some of them below. I'm proud of all that I
          have accomplished so far but even so, I keep challenging myself to
          continue learning and growing my skills. I am only 29, sure there's a
          lot to explore out there, can't wait to see what comes next!
        </p>

        <section className="skills-icons">
          <i className="fa-brands fa-react"></i>
          <i className="fa-brands fa-git-alt"></i>
          <i className="fa-brands fa-js"></i>
          <i className="fa-brands fa-html5"></i>
          <i className="fa-brands fa-css3"></i>
          <i className="fa-brands fa-bootstrap"></i>
          <i className="fa-brands fa-node"></i>
        </section>

        <section>
          <ul className="skills">
            <li>
              Experience with CMS WordPress, developing classic custom themes;
            </li>
            <li>
              Experience creating WordPress plugins for minor enhancements;
            </li>
            <li>Understanding about WordPress hooks (actions and filters);</li>
            <li>
              Experience creating Gutenberg Blocks(static and dynamic ones);
            </li>
            <li>Experience with WP-CLI;</li>
            <li>Proficiency in PHP and JavaScript;</li>
            <li>Familiarity with PSR specifications;</li>
            <li>Experience with React.js;</li>
            <li>Experience with Headless CMS;</li>
            <li>Experience consuming REST APIs.</li>
            <li>Experience with GraphQL for WordPress;</li>
            <li>Experience with PHPUnit for unit tests;</li>
            <li>Experience with Docker;</li>
            <li>Experience with CI/CD (deployment through Docker on AWS);</li>
            <li>
              Experience setting up workflows specific with Github Actions;
            </li>
            <li>
              Experience of Node and package managers such as NPM and Composer
              for PHP;
            </li>
            <li>Experience with Git for version control;</li>
            <li>
              Experience with migrations between servers, working with server
              management tools and processes such as cPanel, phpMyAdmin, DNS
              records;
            </li>
            <li>Knowledge of Filezilla (FTP transfer);</li>
            <li>Knowledge of relational databases such MySQL;</li>
            <li>
              Great understanding of SEO, usability best practices and UX
              design;
            </li>
            <li>Knowledge of Gulp and Webpack and automation tools;</li>
            <li>
              Ability to convert design mockups into responsive applications
              using HTML5, Javascript and CSS preprocessors like SCSS;
            </li>
            <li>Excellent research and troubleshooting skills;</li>
            <li>Ability of delivering solutions on time and on budget.</li>
          </ul>
        </section>

        <section className="center p-x3">
          <button className="button-dark">
            <Link to="/portfolio">Portfolio</Link>
          </button>
        </section>
      </main>
    </>
  );
}
