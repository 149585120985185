import React from "react";
import HireMe from "../components/HireMe";

export default function PageHireMe() {
  return (
    <>
      <HireMe />
    </>
  );
}
