import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
//import { AiFillTag } from "react-icons/ai";
//import ReactMarkdown from "react-markdown";

function SingleProject() {
  const location = useLocation();
  const path = location.pathname.split("/")[3];

  console.log(path);

  const [project, setProject] = useState([]);

  useEffect(() => {
    async function loadProject() {
      const response = await fetch(
        `https://blog.sarahjobs.com/wp-json/wp/v2/projects/${path}`
      );
      if (!response.ok) {
        //ops, something went wrong
        return;
      }
      const project = await response.json();
      setProject(project);
    }
    loadProject();
  }, []);

  console.log(project);

  console.log(project[2]);

  return (
    <div>
      oie avançamos
      <article className="">
        <ul className="grid_item_tags">
          <h1 className="grid_item_tags_tag">{project.id}</h1>
          <li className="grid_item_tags_tag">{project.id}</li>
          <li className="grid_item_tags_tag">{project.date_gmt}</li>
        </ul>
        <h2 className="page_title">{project.id}</h2>
        <ul className="grid_item_tags">
          <li className="grid_item_tags_date">
            {project.readable_publish_date}
          </li>
        </ul>
        <Link to={`../project/${project.slug}/${project.id}`}>
          <img
            src={project.social_image}
            alt={project.title}
            className="grid_item_img"
          ></img>
        </Link>
        <div className="post_published">
          <p>Post originally published {""}</p>
        </div>

        <p>{project.date}</p>
        <p>{project.status}</p>
        <p>{project.type}</p>
        <p>{project.slug}</p>
      </article>
    </div>
  );
}

export default SingleProject;
