import React, { useEffect, useState } from "react";
import { useUserData } from "../utils/UserData";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { BiGitRepoForked } from "react-icons/bi";

export default function PageGithub() {
  const [repo, setRepo] = useState([]);
  const data = useUserData();

  useEffect(() => {
    fetch(data.apiToConsume.github)
      .then((res) => res.json())
      .then((res) => {
        setRepo(res);
      });
  },[data.apiToConsume.github]);

//   console.log(repo);

  return (
    <>
      <main className="page">
        <h2 className="page_title">Code Samples</h2>
        <p>{data.gitHubPageDesc}</p>

        <section className="grid">
          {repo.map((repo) => {
            return (
              <article key={repo.id} className="grid_item">
                <ul className="grid_item_tags">
                    <li className="grid_item_tags_tag">{repo.topics.join(', ')}</li> 
                </ul>

                <Link to={repo.svn_url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="grid_item_title"> 
                      {repo.name.replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
                </Link>
                
                <p>{repo.description}</p>

                <div className="grid_item_buttons">
                  <ul className="grid_item_tags">
                  <Link to={repo.svn_url} target="_blank" rel="noopener noreferrer"><li className="grid_item_tags_star"><AiFillStar /><p>{' '}{repo.stargazers_count}</p></li></Link>

                  <Link to={`${repo.svn_url}/fork`} target="_blank" rel="noopener noreferrer"><li className="grid_item_tags_fork"><BiGitRepoForked /><p>{'  '}{repo.forks_count}</p></li></Link>
                  </ul>
                </div>

                <div className="grid_item_buttons">
                  <Link to={repo.svn_url} target="_blank" rel="noopener noreferrer" className="front_button">View on Github </Link>
                </div>

                {/* <ul className="grid_item_tags"><li className="grid_item_tags_tag">{repo.language} </li></ul> */}
              </article>
            );
          })}
        </section>
      </main>
    </>
  );
}
