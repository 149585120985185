import React from "react";
import SinglePost from "../components/SinglePost";

export default function PageSinglePost() {
  return (
    <div>
      <main className="page">
        <SinglePost />
      </main>
    </div>
  );
}
