import React from "react";

export default function HireMe() {
  return (
    <main className="p-x3 container" id="projects">
      <div className="">
        <h2 className="center m-tb-x2 line-after-accent">Hire Me</h2>
        <p className="m-tb-x2 center">
          Let's chat and understant if I can help you company. I am available
          for part time freelance jobs.
        </p>
      </div>
    </main>
  );
}
