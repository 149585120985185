import React, { useState, useEffect } from "react";
//import { BsGithub } from "react-icons/bs";

//import portfolioImg from "../assets/frontendportfolio.png";
//import rgparapetsImg from "../assets/rg-para-pets.png";
//import dentistThemeImg from "../assets/dentist-theme-wp.png";
//import purpleIshThemeImg from "../assets/purpleIsh-theme.png";
//import codepenImg from "../assets/codepen.png";
//import resumeThemeImg from "../assets/resumeTheme.png";
//import techConviteImg from "../assets/techConvite.png";

//const portfolioURL = "https://sarahjobs.com/";
//const rgparapetsUrl = "https://rgparapets.com/";
//const techconviteUrl = "https://tech-convite.sarahjobs.com/";
//const dentistThemeUrl = "https://dentist-theme.sarahjobs.com/";
//const resumeThemeUrl = "https://resumetheme.ga/";
//const codepenUrl = "https://codepen.io/sarahcssiqueira";
//const purpleIshThemeUrl = "https://purpleish-theme.sarahjobs.com/";
/* Github URLs */
//const resumeThemeGit = "https://github.com/sarahcssiqueira/wp-resume-theme";
//const purpleIshThemeGit = "https://github.com/sarahcssiqueira/purpleish-theme";
//const codepenGit = "https://github.com/sarahcssiqueira/codepen";

function ProjectsList() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch("https://blog.sarahjobs.com/wp-json/wp/v2/projects")
      .then((res) => res.json())
      .then((res) => {
        setProjects(res);
      });
  }, []);

  //console.log(projects);

  return (
    <section className="grid">
      {projects.map((project) => {
        return (
          <article key={project.id} className="grid_item">
            <img
              src={project.better_featured_image.source_url}
              alt={project.title.rendered}
              className="grid_item_img"
            ></img>

            <h1 className="grid_item_title">{project.title.rendered}</h1>
            <span
              dangerouslySetInnerHTML={{ __html: project.content.rendered }}
            ></span>
          </article>
        );
      })}
    </section>
  );
}

export default ProjectsList;
