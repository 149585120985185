import React from "react";
import { useUserData } from "../utils/UserData";
import PostsList from "../components/PostsList";

export default function PageBlog() {
  const data = useUserData();

  return (
    <div>
      <main className="page">
        <h2 className="page_title">Blog</h2>
        <p>{data.blogPageDesc}</p>
        <PostsList />
      </main>
    </div>
  );
}
