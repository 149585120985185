import React from "react";
import { useUserData } from "../utils/UserData";
import profilePic from "../assets/profilepic.jpg";

export default function AboutPage() {
  const data = useUserData();

  return (
    <>
      <main className="page">
        <figure>
          <img
            src={profilePic}
            alt="profile"
            height="200"
            width="200"
            className="page_img"
          />
        </figure>

        <h2 className="page_title">About</h2>
        <article>
          <p>Hi! I'm {data.name}</p>

          <p>{data.longDescription}</p>
        </article>
      </main>
    </>
  );
}
